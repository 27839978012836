<template>
  <div class="activities--container" :class="{ 'activity-card--mobile': !isFullWidth }">
    <div class="card-info" v-for=" activityData in Tours" :key="activityData.id" >
      <a :href="getPageUrl(activityData.id, activityData.title)" @click="goToDetailsPage(activityData.id)">
        <div class="image--container">
          <img class="card--image" :src="activityData.coverImage" />
          <!--   <div class="is-flex is-align-items-center exclusive--badge mb-2">
            <img src="../../assets/logo-sm--dark.png" class="icon mr-1" />
              <span class="is-size-8">Exclusive</span> 
          </div>  
          <div class="is-flex is-align-items-center activity--duration" v-if="activityData.duration">
            <img src="../../assets/stopwatch.svg" class="mr-1 icon" />
            <span class="duration">{{ translateDuration(activityData.duration) }}</span>
          </div>-->


          <div
          class="is-flex is-align-items-center activity--rating"
          v-if="activityData.reviews && activityData.reviews.length"
        >
          <b-icon icon="star" class="mr-1 star-icon"></b-icon
          ><b>{{ getOverallRatingNumber(activityData.reviews) }}</b>
        </div>
          <!-- <div class="is-flex is-align-items-center exclusive--badge mb-2">
            <img src="../../assets/g-badge.svg" class="icon mr-1" />
          </div>  -->



        </div>
        <div class="card--content">
          <div class="">
            <div class="">
              <p class="title mb-3">{{ activityData.title }}</p>

              <div class="is-flex mb-2">
                <div
                  class="is-flex is-align-items-center activity--duration mr-2"
                  v-if="activityData.city"
                >
                  <img src="../../assets/location-icon.svg" class="mr-1 icon" />
                  <span class="duration">{{ activityData.city }}</span>
                </div>
                <div
                  class="is-flex is-align-items-center activity--duration"
                  v-if="activityData.duration"
                >
                  <img src="../../assets/timer-icon.svg" class="mr-1 icon" />
                  <span class="duration">{{
                    translateDuration(activityData.duration)
                  }}</span>
                </div>
              </div>
            </div>
            

            <div class="is-justify-content-flex-end column price mt-3" v-if="activityData.price">
              <span class="has-text-weight-light mr-1 is-size-7">from</span>
              {{ activityData.privatePrice }}€
            </div>
          </div>
          
<!-- 
          <p class="title mb-1">{{ activityData.title }}</p>

          <div  v-if="!activityData.price">
            <b-tag class="mr-2" type="is-success">FREE</b-tag>
            <b-tag type="is-danger">Limited time</b-tag>
          </div>

          <div class="columns is-mobile mt-2 mb-3" v-if="activityData.price">
            <div class="column pt-2 is-6 seperator">
              <b v-if="!activityData.isPrivateOnly">Private Tour:</b>
              <div class="price mt-1"><img class="mr-1" src="../../assets/ticket-outline.svg" />{{
    activityData.privatePrice }}€</div>
              <span class="mb-2 is-size-8">(up to {{ activityData.maxNumOfParticipants }} people)</span>
            </div>
            <div class="column pt-2 is-6 ml-4">
              <b v-if="!activityData.isPrivateOnly">Shared Tour:</b>
              <div   class="price mt-1"><img class="mr-1" src="../../assets/ticket-outline.svg" />{{ activityData.price
                }}€</div>
              <span v-if="!activityData.isPrivateOnly" class="mb-2 is-size-8">(per person)</span>
              <span v-else class="mb-2 is-size-8">(up to 8 people)</span>
            </div>
          </div> -->
        </div>

      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { ActivitiesMutations } from '../../store/Storetypes';
import { getHoursAndMinutes } from '../../helpers'
import { HelpersMixin } from '../../mixins'

export default {
  props: {
    Tours: {
      type: Array,
      default: () => []
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    isMobileViewOnly: {
      type: Boolean,
      default: false
    },
  },
  mixins: [HelpersMixin],
  data() {
    return {
      getHoursAndMinutes
    }
  },
  methods: {
    ...mapMutations({
      setSelectedActivityId: ActivitiesMutations.SET_SELECTED_ACTIVITY_ID
    }),
    getOverallRatingNumber(ratings){
      if (!ratings.length) return
      const ratingsArray = ratings.map(obj => obj.stars)
      return this.getOverallRating(ratingsArray)
    },
    tourRating(stars, evaluations) {
      // TODO: Add translations and make text be length conditional
      return evaluations.length && stars ? `${stars} | ${evaluations.length} review(s)` : '0 reviews'
    },
    goToDetailsPage(activityId) {
      this.setSelectedActivityId(activityId)
      // this.$router.push({ name: 'details', params: { id: activityId, title: this.slugify(title) } })
    },
    getPageUrl(activityId, title) {
      return `https://www.guiders.pt/details/${activityId}/${this.slugify(title)}`
    },
    translateDuration(duration) {
      return getHoursAndMinutes(duration)
    },
  },
};
</script>

<style lang="scss" scoped>
.columns {
  margin: 0;
}

.column {
  padding: 0
}

.seperator {
  position: relative;
}

.seperator:after {
  content: "";
  position: absolute;
  margin-right: 8px;
  border-radius: 19px;
  right: 0px;
  top: 26%;
  height: 66%;
  border-right: 2px solid #0e3d4d12;
}

@media (min-width: 769px) {
  .column.is-3 {
    flex: auto;
  }
}

/*@media (max-width: 769px) {
  .activities--container {
    grid-template-columns: repeat(1, minmax(min-content, 1fr)) !important;
  }
}*/

.image--container {
  position: relative;
}

/*.activities--container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));
}*/

.exclusive--badge {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #0e3d4d;
  font-weight: 600;

  .icon {
    height: 2.2rem;
    width: 2.2rem;
    object-fit: contain;
  }
}

.activity--duration {
  color: #0e3d4d;
  border-radius: 8px;
   // background-color: #56a0ba14;
background-color: rgb(255 239 167 / 68%);
  padding: 4px 8px;
  font-weight: 400;
  width: -moz-max-content;
  width: max-content;
  .duration {
    font-size: 0.8rem;
  }
  .icon {
    height: 0.8rem;
    width: 0.8rem;
    object-fit: fill;
  }
}


.activity--rating {
  background-color: #ffffffeb;
    color: #0e3d4d;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 9px;
    padding: 2px 10px 2px 6px;
    font-weight: 500;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 0.8rem;
}

.activity-details {
  font-weight: bold;
  color: #446DF6;
}


.card {
  .card-content {
    margin: 1.5rem 0 .95rem 0;
  }
}

.card--content {
  padding: 0.5rem;
  color: #0e3d4d !important;
}

.is-flex {
  align-items: center;
}

.rating {
  font-size: .8rem;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.market-price {
  font-size: .9rem;
}

.title {
  margin-bottom: .4rem;
  font-size: 1.1rem;
  margin-top: .25rem;
}

.price {
  font-size: 1.05rem;
  border-radius: 8px;
  padding: 2px 0;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  border-radius: 2px;
  display: grid;
  align-items: center;
}



.columns {
  margin-bottom: 0;
  align-items: flex-start;

  .column {
    padding: 0;
    height: auto;
  }
}

.row {
  display: inline-flex;
  align-items: center;
  color: #0e3d4dbf;
  margin-bottom: 4px;

  img {
    margin-right: 8px;
  }
}


.tag:not(body) {
  margin: 16px 0px;
}

.card--image {
  height: 200px;
  border-radius: 16px;
  width: -webkit-fill-available;
  object-fit: cover;
}
.display-flex-space-btw {
  align-items: center;
}

.activity-card--mobile {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;

  &.activities--container {
    margin-right: -20px !important;
  }

  .card-info {
    width: 70vw;
    flex: 0 0 auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.tag:not(body) {
  margin: 8px 0px;
}

::v-deep {
  .featured-tag {
    position: absolute;
    top: 0;
    left: 16px;
    margin: 4px
  }

  .card {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 8px;
  }

  .card-content {
    background-color: transparent;
    padding: 0px;
  }
}
</style>
