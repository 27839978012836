<template>
  <section>
  <!--   <img src="../../assets/search-no-results.png" />
    <h4 class="title">Humm... we didn't find any results for what you're looking for</h4>
     -->
     <div class="info--container mt-6 mb-3 is-flex is-align-items-flex-start">
      <vue-lottie class="lottie-an mr-4" :options="lottieOptions" width="100%" height="100%" style="margin: 0;"/>
      <p class="text">Can't find the tour you want? <a class='link' href='https://wa.me/351920043375' target='_blank'>Send a message</a> telling us where you'd like to visit in Portugal and your group size. We'll plan the tour and send you a price quote!</p>
    </div>
  </section>
</template>

<script>
import VueLottie from 'vue-lottie';
export default {
  components: {
    VueLottie,
  },
  data() {
    return {
      lottieOptions: {
                animationData: require('@/assets/animations/info-an.json'),
                loop: true,
                autoplay: true,
            },
          }
        }
}
</script>
<style scoped>
.lottie-an{
  margin: 0;
}
.info--container{
  background-color: #f6fbff;
   padding: 22px;
   border-radius: 17px;
 }

section{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
}

.search-text{
    color: #dedde6 !important
}

img {
    height: auto;
    width: 264px;
    margin-bottom: 57px;
    margin-top: 32px;
}

.title{
    font-size: 1rem;
    text-align: -webkit-center;

}
</style>
