<template>
  <section class="search-container">
    <h1 class="title is-blue mb-4 is-hidden-mobile">
      Search
    </h1>

    <div class="columns search-header">
      <div class="display-flex mb-3 pr-3 is-hidden-desktop">
        <b-input class="search-box" placeholder="Search tours and activities" type="search" icon="arrow-left"
          icon-clickable @icon-click="goHome" v-model="searchText"></b-input>
        <b-dropdown aria-role="list" position="is-bottom-left" :mobile-modal="false">
          <template #trigger>
            <div class="filter-container">
              <img src="../assets/Filter.svg" />
            </div>

          </template>
          <b-dropdown-item @click="sortBy = 'Recommended'" aria-role="listitem">Recommended</b-dropdown-item>
          <b-dropdown-item @click="sortBy = 'Rating'" aria-role="listitem">Rating</b-dropdown-item>
          <b-dropdown-item @click="sortBy = 'Price- Low to high'" aria-role="listitem">Price- Low to
            high</b-dropdown-item>
          <b-dropdown-item @click="sortBy = 'Price- High to low'" aria-role="listitem">Price- High to
            low</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="categories-container">
      <div v-for="(category, index) in ActivityCategories" :key="index">
        <b-checkbox-button class="mr-2" rounded v-model="categoriesToFilter" v-if="Categories.includes(category.index)"
          :native-value="index" type="is-primary">{{
            category.name }}</b-checkbox-button>
      </div>

    </div>

    <div class="columns">
      <div class="column">
        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        <div v-else>
          <div v-if="SearchResults.length">
            <TourCard :Tours="SearchResults"></TourCard>
          </div>
          <NoResults v-else />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { ActivitiesStates } from "../store/Storetypes";
import TourCard from "./elements/TourCard.vue";
import NoResults from "./elements/NoResults.vue";
import { ActivityCategories } from "../enums/ActivityCategories"
export default {
  data() {
    return {
      isLoading: true,
      ActivityCategories,
      Tours: [],
      sortBy: "",
      SearchResults: [],
      Locations: [],
      Categories: [],
      checkedFilters: [],
      FilteredTours: [],
      isFiltersModalActive: false,
      searchText: "",
      categoriesToFilter: [],
      checkedCategories: this.$route.query.category !== undefined
        ? [this.$route.query.category]
        : []
    };
  },

  components: {
    TourCard,
    NoResults,
  },

  computed: {
    ...mapState({
      activities: state => state.activities[ActivitiesStates.ACTIVITIES]
    }),
    search() {
      return this.$route.query.search || this.searchText;
    },
    searchHasChanged() {
      const {
        search,
        categoriesToFilter,
        sortBy
      } = this;
      return {
        search,
        categoriesToFilter,
        sortBy
      };
    }
  },

  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    setFilters() {
      this.SearchResults.map((tour) => {
        if (!this.Locations.includes(tour.city)) {
          this.Locations.push(tour.city);
        }

        if (!this.Categories.includes(tour.categories)) {
          this.Categories.push(tour.categories);
        }
      });
    },
    sortActivities(activities) {
      let RecommendedTours = []
      let sortedActivities = []
      switch (this.sortBy) {
        case "Recommended":
          activities.filter((tour) => {
            if (tour.isFeatured) {
              RecommendedTours.unshift(tour);
            } else {
              RecommendedTours.push(tour);
            }
          });
          return RecommendedTours;
        case "Rating":
          sortedActivities = activities.sort((a, b) => {
            return Number(b.stars) - Number(a.stars);
          });
          return sortedActivities

        case "Price- Low to high":
          sortedActivities = activities.sort((a, b) => {
            return Number(a.price) - Number(b.price);
          });
          return sortedActivities

        case "Price- High to low":
          sortedActivities = activities.sort((a, b) => {
            return Number(b.price) - Number(a.price);
          });
          return sortedActivities

        default:
          return activities;
      }
    },
    setFilteredTours(value) {
      this.FilteredTours = value.filteredTours;
      this.checkedFilters = value.checkedFilters;
      this.checkedCategories = value.checkedFilters.checkboxCategory;
    },
    updateSearch() {
      if (!this.search && !this.sortBy && !this.categoriesToFilter) {
        this.SearchResults = this.activities
        return
      }
      let searchResults = []
      this.activities.forEach((tour) => {
        if (tour.title.toLowerCase().includes(this.search.trim().toLowerCase()) || tour.city.toLowerCase().includes(this.search.trim().toLowerCase())) {
          if (this.categoriesToFilter.length) {
            tour.categories.forEach((category) => {
              if (this.categoriesToFilter.includes(category)) {
                searchResults.push(tour)
              }
            })
          } else
            searchResults.push(tour)
        }
      })

      if (this.sortBy) searchResults = this.sortActivities(searchResults)

      this.SearchResults = [...new Set(searchResults)]
    }
  },

  watch: {
    searchHasChanged() {
      this.updateSearch()
    },
    activities: {
      handler: function () {
        this.Tours = this.activities

        let value = this.$route.query.search;
        const categories = this.$route.query.categories

        if (!value && !categories) {
          this.FilteredTours = this.Tours
          this.SearchResults = this.activities
          this.sortBy = "Recommended";
          this.setFilters();
          this.isLoading = false;
          return
        }
        this.Tours.map((tour) => {
          if (
            tour.title.includes(value) ||
            tour.city.includes(value)
          ) {
            if (tour.isFeatured) {
              this.SearchResults.unshift(tour);
              this.FilteredTours.unshift(tour.id);
            } else {
              this.SearchResults.push(tour);
              this.FilteredTours.push(tour.id);
            }
          }
          if (categories) {
            tour.categories.forEach((category) => {
              if (this.$route.query.categories.includes(category)) {
                if (tour.isFeatured) {
                  this.FilteredTours.unshift(tour.id);
                } else {
                  this.FilteredTours.push(tour.id);
                }
              }
            })
          }
        });

        this.sortBy = "Recommended";
        this.setFilters();
        this.isLoading = false;
      },
      deep: true
    }
  },
  created() {
    if (!this.activities.length)
      this.getActivities()
    this.activities.map((tour) => {
      tour.categories.forEach((category) => {
        if (!this.Categories.includes(category)) {
          this.Categories.push(category);
        }
      })
    });

    this.sortBy = "Recommended";
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  background-color: #f6fbff;
  border-radius: 12px;
  padding: 12px;
  display: flex;
}

.categories-container {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: 2rem;
  margin-left: -20px;
  padding-left: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  .button:not(.is-primary) {
    border-color: transparent;
    background-color: #f6fbff;
}
.category--tab{
    height: 2.5rem;
}
}

.title {
  font-size: 1.35rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.modal-content .card {
  margin: 18px;
}

.search-box .control.has-icons-left .icon.is-left {
  left: 0;
  transform: translate3d(10px, 10px, 10px);
}

.search-container .dropdown {
  display: flex;
  justify-content: flex-end;
}

.search-options {
  display: flex;
  justify-content: flex-end;
}

.search-options .button {
  margin-left: 12px;
}

.search-box {
  margin: 0.75rem;
  width: -webkit-fill-available;
}

.search-container .columns {
  align-items: start;
}

.search-container input {
  margin-bottom: 0 !important;
}

.button {
  color: #0e3d4d !important;

}

::v-deep {

  .button:focus:not(:active) {
    box-shadow: none;
  }

  .categories-container {
    .b-checkbox.checkbox.button{
      border-radius: 28px;
      height: 2.5rem;
      font-weight: 700;
      font-size: .9rem;
    }
    .b-checkbox.checkbox.button:not(.is-primary) {
      border: 2px solid rgba(29, 45, 83, 0.9098039216);
      color: #0e3d4d;
     
    }
  }

  .search-header .input {
    margin-bottom: 0px !important;
  }

  .search-box .input {
    box-shadow: rgb(27 31 35 / 3%) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    border: none;
    border-radius: 42px;
    height: 2.8rem;
    background-color: #f6fbff;
    &::placeholder {
      color: #0e3d4d;
      font-size: 1rem;
    }
  }

  .control.has-icons-left .icon {
    font-size: 15px;
  }

  .control.has-icons-left .input {
    padding-left: 2.8em;
  }

  .sort-btn.button.is-ghost {
    border-radius: 30px;
  }
  .card-info{
    margin-bottom: 1rem;
  }
}

.button-icon {
  margin-right: 8px;
}</style>
